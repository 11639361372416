import { User, UserManager, UserManagerSettings } from 'oidc-client-ts';

import { APP_URL } from '../constants/Env';

const OidcSettings: UserManagerSettings = {
  authority: process.env.REACT_APP_IDSRV_API || '',
  client_id: process.env.REACT_APP_IDSRV_CLIENT_ID || '',
  redirect_uri: `${APP_URL}/${process.env.REACT_APP_IDSRV_REDIRECT_ROUTE}`,
  response_type: 'code',
  scope: process.env.REACT_APP_IDSRV_SCOPE,
  loadUserInfo: true
};

class AuthenticationService {
  public readonly name = 'AuthenticationService';

  private _userManager: UserManager | undefined;
  private _user: User | undefined;

  constructor() {
    this._userManager = undefined;
    this._user = undefined;
  }

  public async startupNoToken() {
    this._userManager = new UserManager({
      ...OidcSettings,
      automaticSilentRenew: true
    });

    // Cleanup user details in memory after signout
    this._userManager.events.addUserSignedOut(() => {
      this._user = undefined;
    });

    // Refresh in-memory copy of the user
    this._userManager.events.addUserLoaded(u => {
      this._user = u;
    });

    return Promise.resolve();
  }

  public startupWithToken(nonceToken: string, prospectId: string): void {
    this._userManager = new UserManager({
      ...OidcSettings,
      acr_values: `otp:${nonceToken} prospect:${prospectId}`,
      automaticSilentRenew: nonceToken ? false : true
    });

    // Cleanup user details in memory after signout
    this._userManager.events.addUserSignedOut(() => {
      this._user = undefined;
    });

    // Refresh in-memory copy of the user
    this._userManager.events.addUserLoaded(u => {
      this._user = u;
    });
  }

  public async startSignIn(currentState?: string) {
    if (this._userManager === undefined) {
      return;
    }

    await this._userManager.signinRedirect({ state: currentState });
  }

  public async finishSignIn(): Promise<string> {
    if (this._userManager === undefined) {
      return Promise.reject();
    }

    const signedInUser = await this._userManager.signinCallback();

    // If successfully authenticated, restore window location
    if (signedInUser && !signedInUser.expired) {
      let state = '{}';

      if (signedInUser.state && typeof signedInUser.state === 'string') {
        state = signedInUser.state;
      }
      this._user = signedInUser;
      return Promise.resolve(state);
    }

    return Promise.reject();
  }

  public get isSignedIn() {
    return this._user !== undefined;
  }

  public startSignout(signoutUrl?: string, state?: string) {
    const post_logout_redirect_uri = signoutUrl;
    return this._userManager?.signoutRedirect({
      post_logout_redirect_uri,
      state
    });
  }

  public async finishSignout() {
    if (this._userManager === undefined) {
      return Promise.reject();
    }

    const signoutState = await this._userManager.signoutRedirectCallback();
    return Promise.resolve(signoutState.userState);
  }

  get accessToken() {
    return this._user?.access_token;
  }

  get user() {
    return {
      firstName:
        this._user?.profile.given_name === '---' ||
        this._user?.profile.given_name === ''
          ? undefined
          : this._user?.profile.given_name,
      lastName:
        this._user?.profile.family_name === '---' ||
        this._user?.profile.given_name === ''
          ? undefined
          : this._user?.profile.family_name,
      tpId: this._user?.profile.sub
    };
  }
}

const authService = new AuthenticationService();
export default authService;
