/**
 * The password validation logic follows the same rules used by our IdSrv
 * which follows the aspnetcore default implementation:
 * https://github.com/dotnet/aspnetcore/blob/7b9e6b06b116b4c3bebfbcb0a2f82fe79c907363/src/Identity/Extensions.Core/src/PasswordValidator.cs#L51
 */
export function containsDigit(password: string) {
  return Array.from(password).find(isDigit) !== undefined;
}

export function containsLowerCaseCharacter(password: string) {
  return Array.from(password).find(isLower) !== undefined;
}

export function containsSpecialCharacter(password: string) {
  return (
    Array.from(password).filter(character => !isLetterOrDigit(character))
      .length > 0
  );
}

function isDigit(character: string): boolean {
  if (character.length !== 1) {
    return false;
  }

  return character >= '0' && character <= '9';
}

function isLower(character: string): boolean {
  if (character.length !== 1) {
    return false;
  }

  return character >= 'a' && character <= 'z';
}

function isUpper(character: string): boolean {
  if (character.length !== 1) {
    return false;
  }

  return character >= 'A' && character <= 'Z';
}

function isLetterOrDigit(character: string): boolean {
  if (character.length !== 1) {
    return false;
  }

  return isUpper(character) || isLower(character) || isDigit(character);
}
