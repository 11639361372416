import { createContext } from 'react';

/**
 * @deprecated
 * TODO: delete together with the old address code when the time comes
 */
export interface IAutoCompleteContext {
  onAddressSelected: ((selectedAddress: string) => Promise<string>) | null;
  onAutocompleteRequested:
    | ((address: string) => Promise<string[] | null>)
    | null;
}

/**
 * @deprecated
 * TODO: delete together with the old address code when the time comes
 */
export const AutoCompleteContext = createContext<IAutoCompleteContext>({
  onAddressSelected: null,
  onAutocompleteRequested: null
});
