import { Theme, useTheme } from '@pmi.web/react-theme';
import { FitLineLogo } from 'components/common/FitLineLogo/FitlineLogo';
import { PMLogo } from 'components/common/PMLogo/PMLogo';

export function Header() {
  const { theme } = useTheme();

  return (
    <header className="z-20 sticky top-0 left-0 right-0 h-[56px] sm:h-[72px] flex flex-row items-center justify-between flex-none bg-white shadow-md px-sm">
      {theme === Theme.FitLine ? (
        <FitLineLogo className="h-[48px] sm:h-[56px]" />
      ) : (
        <PMLogo className="h-[40px] sm:h-[48px]" />
      )}
    </header>
  );
}
