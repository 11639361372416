import { useRegistrationContext } from 'contexts/registration-context/useRegistrationContext';
import { useBoolean } from 'hooks/useBoolean';
import { EmailConfirmationPage } from 'pages';

import ProspectRegistrationV1 from './ProspectRegistrationV1';

export function ProspectRegistration({
  prospectId
}: {
  readonly prospectId: string;
}) {
  const { redirectUrl, resumeToken } = useRegistrationContext();

  const [showEmailConfirmation, { setTrue: askEmailConfirmation }] =
    useBoolean(false);

  if (showEmailConfirmation) {
    return <EmailConfirmationPage />;
  }

  return (
    <ProspectRegistrationV1
      prospectId={prospectId}
      redirectUrl={redirectUrl}
      continueToken={resumeToken}
      onNeedsContinueToken={() => askEmailConfirmation()}
    />
  );
}
