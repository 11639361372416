import { ErrorObject } from 'ajv';

/**
 * BOGDAN: this is a hack to be able to display our own BusinessLogic error messages
 * on specific fields rendered by JsonForms. Here we create custom Ajv error messages
 * that we will pass to JsonForms library. We are violating 'separation of concerns'
 * since we have knowledge of what properties are present in the json schema returned
 * by the server for Prospect Registration
 *
 * We also use the `data` field to tell our component (JSONForm) that the message is already
 * translated and it doesn't need to perform any translations.
 */

export const toAddressAjvError = (message: string): ErrorObject => {
  return {
    instancePath: '/address',
    keyword: 'required',
    data: { translated: true },
    message: message,
    params: {
      missingProperty: 'addressLine1'
    },
    schemaPath: '#/properties/address/required'
  };
};

export const toEmailAjvError = (message: string): ErrorObject => {
  return {
    instancePath: '',
    keyword: 'required',
    data: { translated: true },
    message: message,
    params: {
      missingProperty: 'email'
    },
    schemaPath: '#/required'
  };
};

export const toDateOfBirthAjvError = (message: string): ErrorObject => {
  return {
    instancePath: '',
    keyword: 'required',
    data: { translated: true },
    message: message,
    params: {
      missingProperty: 'dateOfBirth'
    },
    schemaPath: '#/required'
  };
};

export const toTaxIdAjvError = (message: string): ErrorObject => {
  return {
    instancePath: '',
    keyword: 'required',
    data: { translated: true },
    message: message,
    params: {
      missingProperty: 'taxId'
    },
    schemaPath: '#/required'
  };
};
