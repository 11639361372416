/**
This function will output the appropriate label to display on a field given a specific country.
The problem we are trying to solve is the following: the field administrativeDistrictLevel1
can have a different name in different countries: in US it would be called a State, in Canada it
would be called a Province or Territory. The user may be using bulgarian as a language so we need
to display the approrpiate translation based on which country the user is registering in.

The logic is as follows: from field name extract the generic english label for that country (eg. 
Province or Territory because the user is registering in Canada) then we rely on the translation
files (eg. `bg.json`) to translate the label into bulgarian. Thus in the translation files
will need to contain a translation for both "State" and "Province and Territory"
*/

export const labelForCountry = (labelName: string, countryId: string) => {
  if (countryId === 'CA') {
    switch (labelName) {
      case 'givenName.label':
        return 'Given Name';
      case 'salutation.label':
        return 'Salutation';
      case 'email.label':
        return 'Email';
      case 'familyName.label':
        return 'Family Name';
      case 'dateOfBirth.label':
        return 'Date of Birth';
      case 'address.addressLine1.label':
        return 'Address';
      case 'address.zip.label':
        return 'Zip';
      case 'address.locality.label':
        return 'City';
      case 'address.country.label':
        return 'Country';
      case 'address.administrativeDistrictLevel1.label':
        return 'Province or Territory';
      case 'address.administrativeDistrictLevel2.label':
        return 'County';
      case 'phoneNumber.label':
        return 'Phone Number';
      case 'taxId.label':
        return 'Tax ID';
      default:
        return labelName;
    }
  }

  // for all other countries
  switch (labelName) {
    case 'givenName.label':
      return 'Given Name';
    case 'salutation.label':
      return 'Salutation';
    case 'email.label':
      return 'Email';
    case 'familyName.label':
      return 'Family Name';
    case 'dateOfBirth.label':
      return 'Date of Birth';
    case 'address.addressLine1.label':
      return 'Address';
    case 'address.zip.label':
      return 'Zip';
    case 'address.locality.label':
      return 'City';
    case 'address.country.label':
      return 'Country';
    case 'address.administrativeDistrictLevel1.label':
      return 'State';
    case 'address.administrativeDistrictLevel2.label':
      return 'County';
    case 'phoneNumber.label':
      return 'Phone Number';
    case 'taxId.label':
      return 'Tax ID';
    default:
      return labelName;
  }
};
