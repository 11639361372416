import { useQuery } from '@tanstack/react-query';
import { getProspect } from 'services/UserManagementService';

export function useProspect(prospectId?: string, continueToken?: string) {
  const query = useQuery({
    queryKey: ['prospect', prospectId, continueToken],
    queryFn: () => getProspect(prospectId ?? '', continueToken),
    staleTime: 0,
    retry: false,
    enabled: !!prospectId
  });

  return { ...query, error: query.error ?? undefined };
}
