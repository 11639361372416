import { useEffect } from 'react';
import AuthenticationService from 'services/AuthService';

export function AuthCallbackPage() {
  // use this variable to detect duplicate useEffect() calls in dev
  let _finishSigninStarted = false;

  useEffect(() => {
    const finishSignin = async () => {
      if (_finishSigninStarted) {
        return;
      }

      _finishSigninStarted = true;

      await AuthenticationService.startupNoToken();

      const stateSerialized = await AuthenticationService.finishSignIn();
      const state = JSON.parse(stateSerialized);

      console.log(
        `Received State from Identity server ${JSON.stringify(state)}`
      );

      if (state.redirectUrl) {
        window.location.replace(state.redirectUrl);
      } else {
        window.location.replace(`registrationComplete?lang=${state.locale}`);
      }
    };

    finishSignin();
  }, []);

  return <></>;
}
