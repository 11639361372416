import {
  useAppInsightsContext,
  useTrackEvent
} from '@microsoft/applicationinsights-react-js';
import {
  JSONForm,
  type JSONSchema,
  type UISchema,
  type IBusinessLogicError,
  AutoCompleteContext
} from '@pmi.web/registration';
import PMIWeb from '@pmi.web/ui';
import { ErrorObject } from 'ajv';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { IS_PROD_BUILD } from 'constants/Env';
import { useRegistrationContext } from 'contexts/registration-context/useRegistrationContext';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { autoComplete } from 'services/AddressAutoCompleteService';

import AuthenticationService from '../../services/AuthService';
import * as schemaService from '../../services/SchemaService';
import * as userManagement from '../../services/UserManagementService';
import { AddressSuggestion, ProspectData } from '../../types/models';

interface IProspectRegistrationProps {
  readonly country?: string;
  readonly prospectId?: string;
  readonly backgroundColor?: string;
  readonly redirectUrl?: string;
  readonly continueToken?: string;
  readonly onNeedsContinueToken?: (emailHint: string) => void;
}

/**
 *
 * @deprecated
 * TODO: delete when V@ is approved
 */
export default function ProspectRegistrationV1(
  // eslint-disable-next-line unused-imports/no-unused-vars
  props: IProspectRegistrationProps
) {
  const { prospectData, redirectUrl, resumeToken } = useRegistrationContext();
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const appInsights = useAppInsightsContext();

  const trackProspectRegistrationDone = useTrackEvent(
    appInsights,
    'ProspectRegistrationDone',
    {}
  );

  const [loadingSchema, setLoadingSchema] = useState<boolean>(false);
  const [submittingProspect, setSubmittingProspect] = useState<boolean>(false);

  const [prospect, setProspect] = useState<ProspectData | undefined>(
    prospectData ?? {}
  );
  const [formValidationErrors, setFormValidationErrors] = useState<any[]>([]);
  const [error, setError] = useState<unknown>();
  const [schema, setSchema] = useState<JSONSchema>({});
  const [uiSchema, setUiSchema] = useState<UISchema>();
  const [backendJsonSchemaErrors, setBackendJsonSchemaErrors] = useState<any[]>(
    []
  );
  const [businessLogicErrors, setBusinessLogicErrors] = useState<
    IBusinessLogicError[]
  >([]);
  const [lastSuggestedAddresses, setLastSuggestedAddresses] = useState<
    AddressSuggestion[] | null
  >(null);

  // use this variable to detect duplicate useEffect() calls in dev
  let _getProspectAndCountryStarted = false;

  useEffect(() => {
    if (_getProspectAndCountryStarted) {
      return;
    }

    _getProspectAndCountryStarted = true;

    if (prospect?.address?.country) {
      getSchema(prospect.address.country);
    }
  }, []);

  const getSchema = async (countryCode: string) => {
    setLoadingSchema(true);
    try {
      const [schema, uiSchema] = prospect?.id
        ? await schemaService.getOnboardingSchema(countryCode)
        : await schemaService.getBootstrapSchema(countryCode);
      // let schema = JSON.parse(JSON.stringify(demoSchema));
      // let uiSchema = JSON.parse(JSON.stringify(demoUiSchema));

      if (!schema) {
        throw new Error('Could not retrieve schema.');
      }

      if (!uiSchema) {
        throw new Error('Could not retrieve UISchema.');
      }

      // bogdan: HACK! if we're doing registration continuation, don't allow the user to change their email address
      if (resumeToken && resumeToken.length > 0) {
        const found = uiSchema.elements.find(
          (x: any) => x.scope === '#/properties/email'
        );
        if (found) {
          found.options = { readonly: true };
        }
      }

      setSchema(schema);
      setUiSchema(uiSchema);
    } catch (e) {
      setError(e);
    } finally {
      setLoadingSchema(false);
    }
  };

  const onAutocompleteRequested = async (address: string) => {
    const suggestions = await autoComplete(
      address,
      prospect?.address?.country ?? ''
    );
    if (!suggestions) {
      return null;
    }
    setLastSuggestedAddresses(suggestions);
    return suggestions?.map(x => x.suggestion);
  };

  const onAddressSelected = async (selectedSuggestion: string) => {
    const selectedAddress = lastSuggestedAddresses?.find(
      x => x.suggestion === selectedSuggestion
    );
    if (!selectedAddress) {
      console.error(
        'an error occurred: cannot find the selected suggested address'
      );
      return selectedSuggestion;
    }

    // take the selected suggested address from Address Lookup service and place it in the prospect's address
    if (prospect) {
      const newProspect = {
        ...prospect,
        address: {
          addressLine1: selectedAddress.addressLine1,
          zip: selectedAddress.postCode,
          administrativeDistrictLevel1: selectedAddress.administrativeArea,
          locality: selectedAddress.locality,
          administrativeDistrictLevel2:
            (selectedAddress.subAdministrativeArea?.length ?? 0) > 0
              ? selectedAddress.subAdministrativeArea
              : undefined
        }
      };
      setProspect(newProspect);
      return newProspect.address.addressLine1;
    }

    return selectedSuggestion;
  };

  const reformatErrors = (resp: any): [any[], IBusinessLogicError[]] => {
    const schemaErrors: any[] = [];
    const bsnsLogicErrors: IBusinessLogicError[] = [];

    // we have a number of json schema error
    if (resp.errors && Array.isArray(resp.errors)) {
      for (const i in resp.errors) {
        schemaErrors.push({
          instancePath: '/' + resp.errors[i].key,
          message: resp.errors[i].value[0],
          keyword: '',
          schemaPath: '',
          params: []
        });
      }
    } else if (resp.error && resp.errors['']) {
      // we have ONE json schema validation server in a weird format
      console.error('Unhandled error');
      console.error(resp);
    }

    if (resp.title && resp.status && resp.detail) {
      // we have a BusinessLogic error
      if (
        resp.title === 'Conflict' &&
        resp.status === 409 &&
        resp.detail === 'address is flagged as duplicate'
      ) {
        bsnsLogicErrors.push({
          field: 'address',
          message: t('Address is already in use')
        });
      }

      if (
        resp.title === 'Bad Request' &&
        resp.status === 400 &&
        resp.detail === 'Validation failed' &&
        resp.Errors
      ) {
        if (resp.Errors.dateOfBirth && resp.Errors.dateOfBirth.includes('18')) {
          bsnsLogicErrors.push({
            field: 'dateOfBirth',
            message: t('You must be at least 18 years old')
          });
        }

        if (resp.Errors.email && resp.Errors.email.includes('email is taken')) {
          bsnsLogicErrors.push({
            field: 'email',
            message: t('Email is already in use')
          });
        }

        if (
          resp.Errors.payload &&
          resp.Errors.payload.includes('Invalid Tax Id')
        ) {
          bsnsLogicErrors.push({
            field: 'taxId',
            message: t('Invalid Tax Id')
          });
        }
      }

      if (resp.title === 'Bad Request' && resp.status === 400 && resp.errors) {
        if (resp.errors['/taxId']) {
          bsnsLogicErrors.push({
            field: 'taxId',
            message: t('Invalid Tax Id')
          });
        }
      }
    }

    return [schemaErrors, bsnsLogicErrors];
  };

  const submit = async () => {
    if (formValidationErrors && formValidationErrors.length > 0) {
      return;
    }

    if (!isLoading && !prospect?.address?.country) {
      return;
    }

    setSubmittingProspect(true);

    // also save the language
    const prospectData: ProspectData = {
      ...prospect,
      locale: language
    };

    const resp = await userManagement.onboardProspect(
      prospectData,
      resumeToken
    );

    if (resp.ok) {
      const result = await resp.json();
      if (!result.otpNonce) {
        throw Error(`Did not receive an OTP from User Management service`);
      }

      await AuthenticationService.startupWithToken(result.otpNonce, result.id);
      const state = JSON.stringify({
        redirectUrl: redirectUrl,
        locale: language
      });
      await AuthenticationService.startSignIn(state);

      trackProspectRegistrationDone({});
      appInsights.core.flush(false);

      // bogdan: don't call setSubmittingProspect(false) on this branch of code because we will get redirected to
      // identity server for OTP exchange, and we want to show the loading screen ( which setSubmittingProspect(true); triggers)
      // until the redirect

      return;
    }

    const result = await resp.json();
    const [jsonSchemaErrors, businessLogicErrors] = reformatErrors(result);

    setBackendJsonSchemaErrors([...jsonSchemaErrors]);
    setBusinessLogicErrors([...businessLogicErrors]);
    setSubmittingProspect(false);
  };

  const onDataChange = useCallback((data: any, errors: ErrorObject[]) => {
    setProspect(() => data);
    setFormValidationErrors(errors);
  }, []);

  const isLoading = loadingSchema;

  if (submittingProspect) {
    return (
      <div className="min-h-[150px] flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="space-y-sm">
      <h1>{t('My Data')}</h1>
      {!!error && <p>{error?.toString()}</p>}

      {isLoading && (
        <div className="min-h-[150px] flex items-center justify-center">
          <LoadingSpinner />
        </div>
      )}

      <AutoCompleteContext.Provider
        value={{ onAddressSelected, onAutocompleteRequested }}
      >
        {!isLoading && schema && uiSchema && (
          <>
            <JSONForm
              env={IS_PROD_BUILD ? 'production' : 'development'}
              defaultFormData={prospect}
              onDataChange={onDataChange}
              schema={schema}
              uiSchema={uiSchema}
              locale={language}
              country={prospect?.address?.country ?? ''}
              backendJsonSchemaErrors={backendJsonSchemaErrors}
              businessLogicErrors={businessLogicErrors}
            />
            <PMIWeb.Components.PrimaryButton
              type="submit"
              onClick={submit}
              disabled={isLoading}
            >
              {t('Submit')}
            </PMIWeb.Components.PrimaryButton>
          </>
        )}
      </AutoCompleteContext.Provider>
    </div>
  );
}
