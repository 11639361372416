import { Theme, useTheme } from '@pmi.web/react-theme';
import PMIWeb from '@pmi.web/ui';
import { fireConfettiGun } from 'effects';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { openWindow } from 'utils/window';

export function OnboardedPage() {
  const { t } = useTranslation();
  const { theme } = useTheme();

  useEffect(() => {
    const confettiColor = theme === Theme.FitLine ? 'red' : 'blue';
    fireConfettiGun(confettiColor);
  }, [theme]);

  return (
    <div className="space-y-sm flex flex-col items-center">
      <h1>{t('Thank you')}</h1>
      <p>{t('Your new password has been saved')}</p>
      <PMIWeb.Components.PrimaryButton
        onClick={() => {
          openWindow('pmebusiness', process.env.REACT_APP_LINK_WEBSHOP ?? '');
        }}
      >
        {t('Go to shop')}
      </PMIWeb.Components.PrimaryButton>
    </div>
  );
}
