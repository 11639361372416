import PMIWeb from '@pmi.web/ui';
import { IS_PROD_BUILD } from 'constants/Env';
import { fireConfettiGun } from 'effects';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { openWindow } from 'utils/window';

import { EasterEggSuccessImg } from './EasterEggSuccessImg';

export function RegistrationCompletePage() {
  const { t } = useTranslation();

  useEffect(() => {
    fireConfettiGun('red');
  }, []);

  return (
    <div className="flex flex-col items-center gap-md">
      {!IS_PROD_BUILD && <EasterEggSuccessImg />}

      <h1>{t('Congratulations!')}</h1>
      <p>{t('Your registration is completed.')}</p>

      <PMIWeb.Components.PrimaryButton
        onClick={() => {
          openWindow('pmebusiness', process.env.REACT_APP_LINK_WEBSHOP ?? '');
        }}
      >
        {t('Go to shop')}
      </PMIWeb.Components.PrimaryButton>
    </div>
  );
}
