import { createContext } from 'react';
import { ProspectData } from 'types/models';

export interface IRegistrationContextValues {
  readonly redirectUrl: string | undefined;
  readonly resumeToken: string | undefined;
  readonly emailRecoveryHint: string | undefined;
  readonly prospectData: ProspectData | undefined;
}

export const RegistrationContext =
  createContext<IRegistrationContextValues | null>(null);
