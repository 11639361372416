import {
  useAppInsightsContext,
  useTrackEvent
} from '@microsoft/applicationinsights-react-js';
import { CustomerRegistrationForm } from '@pmi.web/react-user-forms';
import PMIWeb from '@pmi.web/ui';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { useRegistrationContext } from 'contexts/registration-context/useRegistrationContext';
import { useCustomerRegistration } from 'hooks/useCustomerRegistration';
import { useProspectOnboard } from 'hooks/useProspectOnboard';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import authService from 'services/AuthService';
import { CustomerData, ProspectData } from 'types/models';

export function CustomerRegistrationV2() {
  const appInsights = useAppInsightsContext();
  const { redirectUrl, prospectData, resumeToken } = useRegistrationContext();

  const {
    t,
    i18n: { language }
  } = useTranslation();

  const {
    data: registrationResult,
    // error, // TODO: do something with these backend errors
    isLoading: isLoadingCustomerRegistration,
    register
  } = useCustomerRegistration();

  const {
    data: onboardingResult,
    // error, // TODO: do something with these backend errors
    isLoading: isLoadingProspectOnboarding,
    onboard
  } = useProspectOnboard();

  const isLoading =
    isLoadingCustomerRegistration || isLoadingProspectOnboarding;
  const result = registrationResult || onboardingResult;

  const trackCustomerRegistrationDone = useTrackEvent(
    appInsights,
    'CustomerRegistrationDone',
    {}
  );

  useEffect(() => {
    if (result) {
      authService.startupWithToken(result.otpNonce, result.id);
      const state = JSON.stringify({
        redirectUrl: redirectUrl,
        locale: language
      });

      trackCustomerRegistrationDone({});
      appInsights.core.flush(false);

      authService.startSignIn(state);
    }
  }, [result]);

  if (isLoading || result) {
    return (
      <div className="min-h-[150px] flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <CustomerRegistrationForm
        // TODO: allow this to be optional. Should be part of the default values
        country={prospectData?.address?.country ?? ''}
        // TODO: make this optional. Should be part of the default values
        sponsorId={prospectData?.sponsor ?? ''}
        language={language}
        formId="customer-registration-form"
        onSubmit={data => {
          if (prospectData?.id) {
            onboard(data as ProspectData, resumeToken);
          } else {
            register(data as CustomerData);
          }
        }}
        // TODO: make this optional.. is not needed right now
        onValidation={() => void 0}
        defaultValues={prospectData}
      />

      <div className="w-full flex justify-end mt-md">
        <PMIWeb.Components.PrimaryButton
          disabled={isLoading}
          type="submit"
          form="customer-registration-form"
        >
          {t('Submit')}
        </PMIWeb.Components.PrimaryButton>
      </div>
    </>
  );
}
