import { Theme, useTheme } from '@pmi.web/react-theme';
import { useTranslation } from 'react-i18next';

import { LanguageSelector } from '../common/LanguageSelector/LanguageSelector';

export function Footer() {
  const { theme } = useTheme();
  const { t, i18n } = useTranslation();
  const currentYear = '' + new Date().getFullYear();

  return (
    <footer className="text-slate bg-secondary p-md md:p-xl space-y-xs">
      <h2>{theme === Theme.FitLine ? 'FitLine' : 'PM-International'}</h2>
      {i18n.isInitialized && <LanguageSelector />}
      <p>
        <small>
          {t('© 1993-{{currentYear}} PM-International - All rights reserved.', {
            currentYear
          })}
        </small>
      </p>
    </footer>
  );
}
