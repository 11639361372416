import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { ProspectRegistration } from 'components/prospect-registration';
import { useProspectId } from 'contexts/registration-context/hooks/useProspectId';

export function RegistrationResumePage() {
  const prospectId = useProspectId();

  if (prospectId) {
    return <ProspectRegistration prospectId={prospectId} />;
  }

  return (
    <div className="min-h-[150px] flex items-center justify-center">
      <LoadingSpinner />
    </div>
  );
}
