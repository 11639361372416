import PMIWeb from '@pmi.web/ui';
import { IS_PROD_BUILD } from 'constants/Env';
import { useTranslation } from 'react-i18next';
import { openWindow } from 'utils/window';

import { EasterEggNotFoundImg } from './EasterEggNotFoundImg';

export function NotFoundPage() {
  const { t } = useTranslation();

  return (
    <div className="text-center flex flex-col items-center">
      {!IS_PROD_BUILD && <EasterEggNotFoundImg />}
      <br />
      <h1 className="text-5xl text-error">404</h1>
      <p>
        {IS_PROD_BUILD
          ? 'Page not found'
          : "I'm sorry but FitBear cannot find what you are looking for"}
      </p>

      <br />

      <PMIWeb.Components.PrimaryButton
        onClick={() => {
          openWindow('pmebusiness', process.env.REACT_APP_LINK_WEBSHOP ?? '');
        }}
      >
        {t('Go to shop')}
      </PMIWeb.Components.PrimaryButton>
    </div>
  );
}
