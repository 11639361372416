import { useTranslation } from 'react-i18next';

export function EmailConfirmedSuccessfully() {
  const { t } = useTranslation();

  return (
    <div className="space-y-xs">
      <h1>{t('Confirm your email address')}</h1>
      <p>
        {t(
          'Success! Please follow the instructions sent to your email address'
        )}
      </p>
    </div>
  );
}
