import { useSearchParams } from 'react-router-dom';

import { isAllowedRedirectUrl } from '../utils/RedirectUrlUtils';

export function useRedirectUrl() {
  const [searchParams] = useSearchParams();
  const urlParam = searchParams.get('redirectUrl') ?? undefined;
  const redirectUrl = urlParam
    ? isAllowedRedirectUrl(urlParam)
      ? urlParam
      : undefined
    : undefined;

  return redirectUrl;
}
