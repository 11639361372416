import {
  useAppInsightsContext,
  useTrackEvent
} from '@microsoft/applicationinsights-react-js';
import {
  JSONForm,
  type JSONSchema,
  type UISchema,
  type IBusinessLogicError,
  AutoCompleteContext
} from '@pmi.web/registration';
import PMIWeb from '@pmi.web/ui';
import { ErrorObject } from 'ajv';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { IS_PROD_BUILD } from 'constants/Env';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { autoComplete } from 'services/AddressAutoCompleteService';

import AuthenticationService from '../../services/AuthService';
import * as schemaService from '../../services/SchemaService';
import * as userManagement from '../../services/UserManagementService';
import { AddressSuggestion, CustomerData } from '../../types/models';

/**
 * @deprecated
 * TODO: delete when V2 is approved
 */
export function CustomerRegistrationV1(props: {
  readonly country: string;
  readonly sponsorId: string;
  readonly redirectUrl: string | undefined;
}) {
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const appInsights = useAppInsightsContext();

  const trackCustomerRegistrationDone = useTrackEvent(
    appInsights,
    'CustomerRegistrationDone',
    {}
  );

  const [loadingSchema, setLoadingSchema] = useState<boolean>(false);
  const [submittingCustomer, setSubmittingCustomer] = useState<boolean>(false);

  const [customer, setCustomer] = useState<CustomerData>({
    sponsorId: props.sponsorId,
    address: { country: props.country.toUpperCase() }
  });
  const [formValidationErrors, setFormValidationErrors] = useState<any[]>([]);
  const [error, setError] = useState<unknown>();
  const [schema, setSchema] = useState<JSONSchema>({});
  const [uiSchema, setUiSchema] = useState<UISchema>();
  const [backendJsonSchemaErrors, setBackendJsonSchemaErrors] = useState<any[]>(
    []
  );
  const [businessLogicErrors, setBusinessLogicErrors] = useState<
    IBusinessLogicError[]
  >([]);

  const [lastSuggestedAddresses, setLastSuggestedAddresses] = useState<
    AddressSuggestion[] | null
  >(null);

  useEffect(() => {
    getSchema(props.country);
  }, [props.country]);

  const getSchema = async (countryCode: string) => {
    setLoadingSchema(true);
    try {
      const [schema, uiSchema] = await schemaService.getOnboardingSchema(
        countryCode
      );

      if (!schema) {
        throw new Error('Could not retrieve schema.');
      }

      if (!uiSchema) {
        throw new Error('Could not retrieve UISchema.');
      }

      setSchema(schema);
      setUiSchema(uiSchema);
    } catch (e) {
      setError(e);
    } finally {
      setLoadingSchema(false);
    }
  };

  const onAutocompleteRequested = async (address: string) => {
    const suggestions = await autoComplete(address, props.country);
    if (!suggestions) {
      return null;
    }
    setLastSuggestedAddresses(suggestions);
    return suggestions?.map(x => x.suggestion);
  };

  const onAddressSelected = async (selectedSuggestion: string) => {
    const selectedAddress = lastSuggestedAddresses?.find(
      x => x.suggestion === selectedSuggestion
    );
    if (!selectedAddress) {
      console.error(
        'an error occurred: cannot find the selected suggested address'
      );
      return selectedSuggestion;
    }

    const newCustomer = {
      ...customer,
      address: {
        country: customer?.address?.country,
        addressLine1: selectedAddress.addressLine1,
        zip: selectedAddress.postCode,
        administrativeDistrictLevel1: selectedAddress.administrativeArea,
        locality: selectedAddress.locality,
        administrativeDistrictLevel2: selectedAddress.subAdministrativeArea
      }
    };
    setCustomer(newCustomer);
    return newCustomer.address.addressLine1;
  };

  const reformatErrors = (resp: any): [any[], IBusinessLogicError[]] => {
    const schemaErrors: any[] = [];
    const bsnsLogicErrors: IBusinessLogicError[] = [];

    if (resp.errors && Array.isArray(resp.errors)) {
      for (const error of resp.errors) {
        schemaErrors.push({
          instancePath: '/' + error.key,
          message: error.value[0],
          keyword: '',
          schemaPath: '',
          params: []
        });
      }
    }

    if (resp.title && resp.status && resp.detail) {
      if (
        resp.title === 'Conflict' &&
        resp.status === 409 &&
        resp.detail === 'address is flagged as duplicate'
      ) {
        bsnsLogicErrors.push({
          field: 'address',
          message: t('Address is already in use')
        });
      }

      if (
        resp.title === 'Bad Request' &&
        resp.status === 400 &&
        resp.detail === 'Validation failed' &&
        resp.Errors
      ) {
        if (resp.Errors.dateOfBirth && resp.Errors.dateOfBirth.includes('18')) {
          bsnsLogicErrors.push({
            field: 'dateOfBirth',
            message: t('You must be at least 18 years old')
          });
        }

        if (resp.Errors.email && resp.Errors.email.includes('email is taken')) {
          bsnsLogicErrors.push({
            field: 'email',
            message: t('Email is already in use')
          });
        }

        if (
          resp.Errors.payload &&
          resp.Errors.payload.includes('Invalid Tax Id')
        ) {
          bsnsLogicErrors.push({
            field: 'taxId',
            message: t('Invalid Tax Id')
          });
        }
      }

      if (resp.title === 'Bad Request' && resp.status === 400 && resp.errors) {
        if (resp.errors['/taxId']) {
          bsnsLogicErrors.push({
            field: 'taxId',
            message: t('Invalid Tax Id')
          });
        }
      }
    }

    return [schemaErrors, bsnsLogicErrors];
  };

  const submit = async () => {
    if (formValidationErrors && formValidationErrors.length > 0) {
      return;
    }

    setSubmittingCustomer(true);

    const customerData: CustomerData = {
      ...customer,
      locale: language
    };

    const resp = await userManagement.registerCustomer(customerData);

    if (resp.ok) {
      const result = await resp.json();
      if (!result.otpNonce) {
        throw Error('Did not receive an OTP from User Management service');
      }

      await AuthenticationService.startupWithToken(result.otpNonce, result.id);

      const state = JSON.stringify({
        redirectUrl: props.redirectUrl,
        locale: language
      });

      trackCustomerRegistrationDone({});
      appInsights.core.flush(false);

      await AuthenticationService.startSignIn(state);

      return;
    }

    const result = await resp.json();
    const [jsonSchemaErrors, businessLogicErrors] = reformatErrors(result);

    setBackendJsonSchemaErrors([...jsonSchemaErrors]);
    setBusinessLogicErrors([...businessLogicErrors]);
    setSubmittingCustomer(false);
  };

  const onDataChange = useCallback((data: any, errors: ErrorObject[]) => {
    setCustomer(prevState => ({ ...data, sponsorId: prevState.sponsorId }));
    setFormValidationErrors(errors);
  }, []);

  if (submittingCustomer) {
    return (
      <div className="min-h-[150px] flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="space-y-sm">
      <h1>{t('Customer Registration')}</h1>

      {!!error && <p>{error?.toString()}</p>}

      {loadingSchema && (
        <div className="min-h-[150px] flex items-center justify-center">
          <LoadingSpinner />
        </div>
      )}

      {!loadingSchema && uiSchema && schema && (
        <AutoCompleteContext.Provider
          value={{ onAddressSelected, onAutocompleteRequested }}
        >
          <JSONForm
            env={IS_PROD_BUILD ? 'production' : 'development'}
            defaultFormData={customer}
            onDataChange={onDataChange}
            schema={schema}
            uiSchema={uiSchema}
            locale={language}
            country={props.country}
            backendJsonSchemaErrors={backendJsonSchemaErrors}
            businessLogicErrors={businessLogicErrors}
          />
          <PMIWeb.Components.PrimaryButton type="submit" onClick={submit}>
            {t('Submit')}
          </PMIWeb.Components.PrimaryButton>
        </AutoCompleteContext.Provider>
      )}
    </div>
  );
}
