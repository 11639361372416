import { ISOCountryCode, parseCountryCode } from '@pmi.web/countries';

/**
 * List of countries where the new registration is allowed.
 * Currrently, this is hardcoded here in the FE because we moved away from
 * the jsonschema approach. To ensure we don't allow any country, we use
 * this list of allowed countries according to https://dev.azure.com/prem1um/pme/_git/pmi.services.um?path=/SeedData
 */
const AllowedRegistrationCountries = [
  /* AT */ ISOCountryCode.Austria,
  /* AX */ ISOCountryCode.AlandIslands,
  /* BE */ ISOCountryCode.Belgium,
  /* CA */ ISOCountryCode.Canada,
  /* DE */ ISOCountryCode.Germany,
  /* EE */ ISOCountryCode.Estonia,
  /* FI */ ISOCountryCode.Finland,
  /* FR */ ISOCountryCode.France,
  /* GF */ ISOCountryCode.FrenchGuiana,
  /* GP */ ISOCountryCode.Guadeloupe,
  /* HK */ ISOCountryCode.HongKong,
  /* HU */ ISOCountryCode.Hungary,
  /* IT */ ISOCountryCode.Italy,
  /* JP */ ISOCountryCode.Japan,
  /* LU */ ISOCountryCode.Luxembourg,
  /* MQ */ ISOCountryCode.Martinique,
  /* PF */ ISOCountryCode.FrenchPolynesia,
  /* RE */ ISOCountryCode.Reunion,
  /* SE */ ISOCountryCode.Sweden,
  /* US */ ISOCountryCode.UnitedStatesAmerica,
  /* YT */ ISOCountryCode.Mayotte
];

export function isRegistrationAllowedForCountry(country: string): boolean {
  try {
    const isoCountry = parseCountryCode(country);
    return AllowedRegistrationCountries.includes(isoCountry);
  } catch (err) {
    return false;
  }
}
