import { AnchorHTMLAttributes, LegacyRef, ReactNode, forwardRef } from 'react';

import './PrimaryLink.scss';

interface IPrimaryLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  readonly children: ReactNode;
  readonly style?: any;
}

function PrimaryLinkComponent(
  props: IPrimaryLinkProps,
  ref: LegacyRef<HTMLAnchorElement>
) {
  const { children, ...anchorProps } = props;

  return (
    <a {...anchorProps} className="pmi_web__PrimaryLink" ref={ref}>
      {children}
    </a>
  );
}

export const PrimaryLink = forwardRef(PrimaryLinkComponent);
