import {
  localizedLanguageName,
  parseSupportedLanguage,
  SupportedLanguage
} from '@pmi.web/localization';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IoLanguage } from 'react-icons/io5';
import { useSearchParams } from 'react-router-dom';
import { onChangeLanguage } from 'services/TranslationsService';

export function LanguageSelector() {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    i18n: { language }
  } = useTranslation();

  const urlLang = useMemo(() => {
    return parseSupportedLanguage(searchParams.get('lang') ?? '' ?? '');
  }, [searchParams]);

  const setUrlLang = useCallback(
    (language: string) => {
      searchParams.set('lang', language);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    onChangeLanguage(urlLang);
  }, [urlLang]);

  return (
    <div className="flex flex-row items-center gap-xs">
      <IoLanguage size={16} />
      <select
        title="Language selector"
        className="text-sm bg-transparent"
        value={language}
        onChange={event => setUrlLang(event.target.value)}
      >
        {Object.values(SupportedLanguage).map(supportedLanguage => (
          <option key={supportedLanguage} value={supportedLanguage}>
            {localizedLanguageName(supportedLanguage)}
          </option>
        ))}
      </select>
    </div>
  );
}
