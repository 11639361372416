import 'assets/styles/tailwind.scss';
// eslint-disable-next-line import/order
import 'assets/styles/globals.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { initClarity } from 'services/Clarity';
import { initTranslations } from 'services/TranslationsService';

import { App } from './App';

(async function startApplication() {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  initClarity();
  await initTranslations();

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
})();
