/**
 * PM International
 * This is a custom control that provides an address auto-completion facility.
 * The user will start typing in an address. A parent component should provide
 * an AutoCompleteContext with callbacks that this component can call when
 * needing autocomplete suggetions as well as when a user has selected an address
 * from the list of suggestions.
 */

import {
  type CellProps,
  uiTypeIs,
  type RankedTester,
  rankWith
} from '@jsonforms/core';
import { withJsonFormsCellProps } from '@jsonforms/react';
import debounce from 'lodash/debounce';
import replace from 'lodash/replace';
import { useContext, useEffect, useState } from 'react';

import {
  AutoCompleteContext,
  IAutoCompleteContext
} from '../../json-form/AddressAutocompleteContext';
import type { VanillaRendererProps } from '../index';
import { withVanillaCellProps } from '../util/index';

/**
 * @deprecated
 * TODO: delete together with the old address code when the time comes
 */
export const AddressAutocompleteCell = (
  props: CellProps & VanillaRendererProps
) => {
  const { data, className, id, enabled, uischema, path, handleChange } = props;

  const [sugesstedAddresses, setSuggestedAddreses] = useState<string[] | null>(
    null
  );

  const [shouldFetchSuggestions, setShouldFetchSuggestions] = useState(false);

  const autoCompleteContext =
    useContext<IAutoCompleteContext>(AutoCompleteContext);

  const inputChanged = (address: string) => {
    // Update local state to allow fetching suggestions after the first input change
    setShouldFetchSuggestions(true);

    setSuggestedAddreses([]);

    handleChange(path, address);
  };

  // Update suggestions when input value changes
  useEffect(() => {
    async function requestSuggestion(address: string) {
      if (
        !autoCompleteContext ||
        !autoCompleteContext.onAutocompleteRequested
      ) {
        return;
      }

      const numCharactersTyped = replace(address, /[ ]?/g, '').length;

      if (numCharactersTyped < 5) {
        return;
      }

      const suggestions = await autoCompleteContext.onAutocompleteRequested(
        address
      );
      setSuggestedAddreses(suggestions);
    }

    const debouncedFetchSuggestions = debounce(requestSuggestion, 500);

    // Check if shouldFetchSuggestions is true before fetching
    if (shouldFetchSuggestions) {
      debouncedFetchSuggestions(data);
    }

    // Cleanup the debounced function on component unmount
    return () => debouncedFetchSuggestions.cancel();
  }, [data, shouldFetchSuggestions, autoCompleteContext]);

  const handleKeyDown = (e: any) => {
    if (e.key === 'Escape') {
      setSuggestedAddreses([]);
    }
  };

  const handleBlur = () => {
    // bogdan: give time to the onClick event on the suggestions to trigger.
    // It seems 150ms is magical. If we use 100ms, the onClick handler does not have
    // time to trigger
    setTimeout(() => {
      setSuggestedAddreses([]);
    }, 150);
  };

  const addressSelected = async (address: string) => {
    setShouldFetchSuggestions(false);
    if (!autoCompleteContext || !autoCompleteContext.onAddressSelected) {
      return;
    }
    await autoCompleteContext.onAddressSelected(address);
    setSuggestedAddreses([]);
  };

  const renderSugestions = () => {
    if (!sugesstedAddresses || sugesstedAddresses.length === 0) {
      return <></>;
    }

    return (
      <div className="jsf-address-sugggestions">
        {sugesstedAddresses.map(x => (
          <div
            key={x}
            className="jsf-address-suggestion-item"
            onClick={_ => addressSelected(x)}
          >
            {x}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="jsf-address-wrapper" onBlur={handleBlur}>
      <input
        title={undefined}
        value={data ?? ''}
        onChange={ev => inputChanged(ev.target.value)}
        onKeyDown={handleKeyDown}
        className={className}
        id={id}
        disabled={!enabled}
        autoFocus={uischema.options && uischema.options.focus}
      />
      {renderSugestions()}
    </div>
  );
};

/**
 * Tester for Address Autocomplete control.
 * @type {RankedTester}
 * @deprecated
 * TODO: delete together with the old address code when the time comes
 */
export const addressAutocompleteCellTester: RankedTester = rankWith(
  3,
  uiTypeIs('Address-Autocomplete')
);

/**
 * @deprecated
 * TODO: delete together with the old address code when the time comes
 */
export default withJsonFormsCellProps(
  withVanillaCellProps(AddressAutocompleteCell)
);
