import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouteError } from 'components/common/ErrorHandler/RouteError';
import { PageLayout } from 'components/layout/PageLayout';
import { RegistrationContextProvider } from 'contexts/registration-context/RegistrationContextProvider';
import {
  AuthCallbackPage,
  NotFoundPage,
  OnboardedPage,
  RegistrationCompletePage,
  RegistrationResumePage,
  RegistrationStartPage,
  SetPasswordPage
} from 'pages';
import { RootRoute } from 'pages/RootRoute';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider
} from 'react-router-dom';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const routesConf = [
  {
    path: '/',
    element: (
      <PageLayout>
        <QueryClientProvider client={queryClient}>
          <RegistrationContextProvider>
            <RootRoute />
          </RegistrationContextProvider>
        </QueryClientProvider>
      </PageLayout>
    ),
    errorElement: (
      <PageLayout>
        <RouteError />
      </PageLayout>
    ),
    children: [
      {
        index: true,
        element: <RegistrationStartPage />
      },
      {
        path: 'account/resume',
        element: <RegistrationResumePage />
      },
      {
        path: 'account/finish/:accessToken',
        element: <SetPasswordPage />
      },
      {
        path: '*',
        element: <Navigate to={'/404'} />
      }
    ]
  },
  {
    path: '/authcallback',
    element: <AuthCallbackPage />
  },
  {
    path: '/registrationComplete',
    element: (
      <PageLayout>
        <RegistrationCompletePage />
      </PageLayout>
    )
  },
  {
    path: 'onboarded',
    element: (
      <PageLayout>
        <OnboardedPage />
      </PageLayout>
    )
  },
  {
    path: '/404',
    element: (
      <PageLayout>
        <NotFoundPage />
      </PageLayout>
    )
  }
];

export function AppRouter() {
  const router = createBrowserRouter(routesConf);

  return <RouterProvider router={router}></RouterProvider>;
}
