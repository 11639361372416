import { PrimaryButton } from '@pmi.web/react-common';
import { ErrorHandler } from 'components/common/ErrorHandler/ErrorHandler';
import { ErrorInfo, ReactNode } from 'react';
import {
  ErrorBoundary as ErrorBoundaryFactory,
  type FallbackProps
} from 'react-error-boundary';

interface IErrorBoundaryProps {
  readonly children: ReactNode;
}

export function ErrorBoundary({ children }: IErrorBoundaryProps) {
  return (
    <ErrorBoundaryFactory onError={logError} fallbackRender={FallbackComponent}>
      {children}
    </ErrorBoundaryFactory>
  );
}

function logError(error: Error, info: ErrorInfo): void {
  console.error('[ErrorBoundary]', error, info);
}

function FallbackComponent(props: FallbackProps) {
  return (
    <div className="min-h-screen w-full flex flex-col items-center justify-center gap-md">
      <ErrorHandler error={props.error} />
      <PrimaryButton onClick={props.resetErrorBoundary}>Retry</PrimaryButton>
    </div>
  );
}
