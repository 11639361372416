import { ReactNode } from 'react';

interface IContentWrapperProps {
  readonly children: ReactNode;
}

export function ContentWrapper(props: IContentWrapperProps) {
  return (
    <section className="bg-white rounded-panel shadow-md max-w-[900px] m-auto p-md sm:p-lg mb-lg">
      {props.children}
    </section>
  );
}
