import { CustomerRegistration } from 'components/customer-registration';
import { ProspectRegistration } from 'components/prospect-registration';
import { useRegistrationContext } from 'contexts/registration-context/useRegistrationContext';

export function RegistrationStartPage() {
  const { prospectData } = useRegistrationContext();

  if (prospectData?.id) {
    return <ProspectRegistration prospectId={prospectData.id} />;
  }

  if (prospectData?.sponsor) {
    return <CustomerRegistration sponsorId={prospectData.sponsor} />;
  }

  return null;
}
