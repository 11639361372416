/**
 * Retrieve the Prospect Onboarding schema
 * @deprecated
 */
export const getOnboardingSchema = async (countryId: string) => {
  const resp = await fetch(
    `${process.env.REACT_APP_USERMANAGEMENT_API}/registration/configuration?country=${countryId}&type=customer`
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let respObject: any = undefined;
  try {
    respObject = await resp.json();
    if (!respObject) {
      throw new Error();
    }
  } catch (e) {
    console.log('Could not deserialize response when retrieving schema.');
    throw new Error('Error when retrieving schema.');
  }

  const schemaParent = respObject?.jsonSchemas?.CustomerOnboard;

  if (!schemaParent) {
    throw new Error('Response from UM API does not contain schema parent');
  }

  if (!schemaParent.jsonSchemaContent) {
    throw new Error(
      'Response from UM API does not contain json schema content'
    );
  }

  if (!schemaParent.uiSchemaContent) {
    throw new Error('Response from UM API does not contain ui schema content');
  }

  return [schemaParent.jsonSchemaContent, schemaParent.uiSchemaContent];
};

/**
 * Retrieve the Prospect Bootstrap schema
 * @deprecated
 */
export const getBootstrapSchema = async (countryId: string) => {
  const resp = await fetch(
    `${process.env.REACT_APP_USERMANAGEMENT_API}/registration/configuration?country=${countryId}&type=customer`
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let respObject: any = undefined;
  try {
    respObject = await resp.json();
    if (!respObject) {
      throw new Error();
    }
  } catch (e) {
    console.log('Could not deserialize response when retrieving schema.');
    throw new Error('Error when retrieving schema.');
  }

  const schemaParent = respObject?.jsonSchemas?.CustomerBootstrap;

  if (!schemaParent) {
    throw new Error('Response from UM API does not contain schema parent');
  }

  if (!schemaParent.jsonSchemaContent) {
    throw new Error(
      'Response from UM API does not contain json schema content'
    );
  }

  if (!schemaParent.uiSchemaContent) {
    throw new Error('Response from UM API does not contain ui schema content');
  }

  return [schemaParent.jsonSchemaContent, schemaParent.uiSchemaContent];
};
