import { parseCountryCode } from '@pmi.web/countries';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { useProspect } from 'hooks/useProspect';
import { ReactNode, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { ProspectData } from 'types/models';

import { useContinueToken } from './hooks/useContinueToken';
import { useCountry } from './hooks/useCountry';
import { useProspectId } from './hooks/useProspectId';
import { useRedirectUrl } from './hooks/useRedirectUrl';
import { useSponsorId } from './hooks/useSponsorId';
import {
  IRegistrationContextValues,
  RegistrationContext
} from './RegistrationContext';
import * as RegistrationHelper from './utils/RegistrationHelper';

interface IRegistrationContextProviderProps {
  readonly children: ReactNode;
}

export function RegistrationContextProvider({
  children
}: IRegistrationContextProviderProps) {
  const urlCountry = useCountry();
  const urlSponsorId = useSponsorId();
  const urlProspectId = useProspectId();
  const urlRedirectUrl = useRedirectUrl();
  const urlContinueToken = useContinueToken();

  const {
    data: prospectOrHint,
    isLoading,
    error
  } = useProspect(urlProspectId, urlContinueToken);

  const contextValue: IRegistrationContextValues = useMemo(() => {
    const country = urlCountry ? parseCountryCode(urlCountry) : undefined;

    let prospectData: ProspectData = {
      id: urlProspectId,
      sponsor: urlSponsorId,
      address: country
        ? {
            country: country
          }
        : undefined
    };

    let emailRecoveryHint: string | undefined = undefined;

    if (prospectOrHint && 'status' in prospectOrHint) {
      emailRecoveryHint = prospectOrHint.hint;
    } else if (prospectOrHint) {
      prospectData = prospectOrHint;
    }

    return {
      redirectUrl: urlRedirectUrl,
      emailRecoveryHint,
      prospectData,
      resumeToken: urlContinueToken
    };
  }, [
    urlCountry,
    urlProspectId,
    urlSponsorId,
    prospectOrHint,
    urlRedirectUrl,
    urlContinueToken
  ]);

  const { isReady, hasValidStartupParams } = useMemo(() => {
    return {
      isReady: !isLoading,
      hasValidStartupParams: RegistrationHelper.hasValidStartupParams({
        urlCountry,
        urlSponsorId,
        urlProspectId
      })
    };
  }, [isLoading, urlCountry, urlProspectId, urlSponsorId]);

  if (!hasValidStartupParams) {
    return <Navigate to={'/404'} />;
  }

  if (error && urlProspectId) {
    if (urlRedirectUrl) {
      window.location.assign(urlRedirectUrl);
      return null;
    }

    return <Navigate to={'/404'} />;
  }

  if (!isReady) {
    return (
      <div className="flex flex-row justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <RegistrationContext.Provider value={contextValue}>
      {children}
    </RegistrationContext.Provider>
  );
}
