import {
  Children,
  ReactComponentElement,
  ReactElement,
  ReactNode
} from 'react';
import { Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Banner, IBannerProps } from './Banner';
import './BannerCarousel.scss';

interface IBannerCarouselProps {
  readonly children: ReactNode;
  readonly autoplay?: boolean;
}

const autoplayProps = {
  delay: 5000,
  disableOnInteraction: true,
  pauseOnMouseEnter: true
};

export function BannerCarousel(props: IBannerCarouselProps) {
  const banners = getChildBanners(props.children);

  return (
    <Swiper
      className="pmiweb--banner-carousel"
      spaceBetween={16}
      pagination={{
        enabled: banners.length > 1,
        clickable: true,
        bulletClass: 'bullet',
        bulletActiveClass: 'bullet-active'
      }}
      modules={[Autoplay, Pagination]}
      autoplay={props.autoplay ? autoplayProps : undefined}
    >
      {banners.map(banner => {
        return (
          <SwiperSlide key={`bannerslide-${banner.key}`}>{banner}</SwiperSlide>
        );
      })}
    </Swiper>
  );
}

function getChildBanners(
  children: ReactNode
): ReadonlyArray<ReactComponentElement<typeof Banner, IBannerProps>> {
  const banners: Array<ReactComponentElement<typeof Banner, IBannerProps>> = [];

  Children.toArray(children).forEach(child => {
    if (isBannerComponent(child)) {
      banners.push(child);
    }
  });

  return banners;
}

function isBannerComponent(
  child: unknown
): child is ReactComponentElement<typeof Banner, IBannerProps> {
  return isReactElement(child) && hasBannerProps(child);
}

function hasBannerProps(
  child: ReactElement
): child is ReactComponentElement<typeof Banner, IBannerProps> {
  return (
    'title' in child.props &&
    'description' in child.props &&
    'countdownTo' in child.props
  );
}

function isReactElement(child: unknown): child is ReactElement {
  return (
    !!child &&
    typeof child === 'object' &&
    'type' in child &&
    'props' in child &&
    'key' in child
  );
}
