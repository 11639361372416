import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { Theme, ThemeManager } from '@pmi.web/react-theme';
import { AppRouter } from 'AppRouter';
import { reactPlugin } from 'libs/monitoring';
import { useMemo } from 'react';

import { ErrorBoundary } from './ErrorBoundary';

export function App() {
  const theme = useMemo(() => {
    return window?.location.host.includes('.pm-international.')
      ? Theme.PM
      : Theme.FitLine;
  }, []);

  return (
    <ErrorBoundary>
      <ThemeManager defaultTheme={theme} />
      <AppInsightsContext.Provider value={reactPlugin}>
        <AppRouter />
      </AppInsightsContext.Provider>
    </ErrorBoundary>
  );
}
