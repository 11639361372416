export function hasValidStartupParams({
  urlCountry,
  urlProspectId,
  urlSponsorId
}: {
  readonly urlCountry: string | undefined;
  readonly urlSponsorId: string | undefined;
  readonly urlProspectId: string | undefined;
}): boolean {
  // Flow 1.
  // Fresh registration under a specific country and sponsor
  if (urlCountry !== undefined && urlSponsorId !== undefined) {
    return true;
  }

  // Flow 2.
  // Recovery of prospect to finish registration
  if (urlProspectId !== undefined) {
    return true;
  }

  return false;
}
