import {
  type CellProps,
  uiTypeIs,
  type RankedTester,
  rankWith
} from '@jsonforms/core';
import { useJsonForms, withJsonFormsCellProps } from '@jsonforms/react';
import { localizedCountryName } from '@pmi.web/countries';

import type { VanillaRendererProps } from '../index';
import { withVanillaCellProps } from '../util/index';

/**
 * @deprecated
 * TODO: delete together with the old address code when the time comes
 */
export const CountryCell = (props: CellProps & VanillaRendererProps) => {
  const { data, className, id, enabled, uischema, path, handleChange } = props;
  const { i18n } = useJsonForms();

  const translatedCountry = localizedCountryName(data, i18n?.locale ?? 'en');

  return (
    <div>
      <input
        value={translatedCountry ?? ''}
        onChange={ev => handleChange(path, ev.target.value)}
        className={className}
        id={id}
        disabled={!enabled}
        autoFocus={uischema.options && uischema.options.focus}
      />
    </div>
  );
};

/**
 * Tester for Country control.
 * @type {RankedTester}
 * @deprecated
 * TODO: delete together with the old address code when the time comes
 */
export const countryCellTester: RankedTester = rankWith(3, uiTypeIs('Country'));

/**
 * @deprecated
 * TODO: delete together with the old address code when the time comes
 */
export default withJsonFormsCellProps(withVanillaCellProps(CountryCell));
