import { useCallback, useState } from 'react';
import { onboardProspect } from 'services/UserManagementService';
import { ProspectData } from 'types/models';

export function useProspectOnboard() {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();
  const [data, setData] = useState<{
    readonly id: string;
    readonly otpNonce: string;
  }>();

  const onboard = useCallback(
    async (prospectData: ProspectData, continueToken: string | undefined) => {
      setLoading(true);

      try {
        const response = await onboardProspect(prospectData, continueToken);
        const result = await response.json();

        if (response.ok) {
          if (!result.otpNonce) {
            throw Error('Did not receive an OTP from User Management service');
          }

          setData(result);
        } else {
          setError(result);
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return {
    isLoading,
    error,
    data,
    onboard
  };
}
