import { useRegistrationContext } from 'contexts/registration-context/useRegistrationContext';
import { Navigate } from 'react-router-dom';

import { CustomerRegistrationV1 } from './CustomerRegistrationV1';

export function CustomerRegistration({
  sponsorId
}: {
  readonly sponsorId: string;
}) {
  const { redirectUrl, prospectData } = useRegistrationContext();
  const country = prospectData?.address?.country;

  if (!country) {
    return <Navigate to={'/404'} replace />;
  }

  return (
    <CustomerRegistrationV1
      country={country}
      sponsorId={sponsorId}
      redirectUrl={redirectUrl}
    />
  );
}
