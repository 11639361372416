import { PMLogoWithClaim } from './PMLogoWithClaim';
import { PMLogoWithoutClaim } from './PMLogoWithoutClaim';

interface IPMLogoProps {
  readonly showClaim?: boolean;
  readonly className?: string;
}

export function PMLogo(props: IPMLogoProps) {
  if (props.showClaim) {
    return <PMLogoWithClaim className={props.className} />;
  }

  return <PMLogoWithoutClaim className={props.className} />;
}
