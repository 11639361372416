import { PrimaryLink } from '../buttons/PrimaryLink/PrimaryLink';

interface IBannerCtaProps {
  readonly url: string;
  readonly label: string;
  readonly onCta?: () => void;
}

export function BannerCta(props: IBannerCtaProps) {
  return (
    <PrimaryLink
      onClick={() => {
        if (props.onCta) {
          props.onCta();
        }
      }}
      href={props.url}
    >
      {props.label}
    </PrimaryLink>
  );
}
